<template>
<!--  题库管理-->
<div class="questionBank">
  <a-spin :indicator="indicator" :spinning="spinning">
    <div style="overflow: hidden;display: flex;justify-content: space-between;">
      <div class="menu_select">
        <div style="display: flex;justify-content: space-between;padding: 5px;width: 100%" >
          <a-button @click="()=>this.typeAdd = true"  type="primary">新增分类</a-button>
          <a-button  @click="typeShow === false ? show() : hide()">修改分类</a-button>
        </div>
        <a-menu
            style="width: 100%;"
            mode="inline"
            v-model="menu"
        >
          <a-menu-item
              v-for="(item,index) in typeInfo"
              :key="index"
              @click="leftChange(item.tyPe,index)"
          ><div style="display: flex;justify-content: space-between">
            <span >{{item.name}}</span>
            <a onClick="event.cancelBubble = true" @click="typeEditBtn(item)" v-show="typeShow" color="#87d068">
            修改
          </a>
          </div>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="InfoTable">
        <div class="select_Info">
          <div>
            名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 180px" size="default" @pressEnter="()=>this.searchOk()" />&nbsp;&nbsp;&nbsp;
            <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
            <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button>
          </div>
        </div>
        <div class="table_Info">
          <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
          <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" rowKey="id"   :pagination="false"
          >

            <span slot="code" slot-scope="text,item,index">
              <span v-for="list in item.question_code">{{list + ';'}}</span>
            </span>
            <span slot="type" slot-scope="text,item,index">
              {{item.type === 'radio' ? '单选':'多选'}}
            </span>
            <span slot="operate" slot-scope="text,item,index">
              <a style="margin-right: 10px" @click="editBtn(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
          </a-table>
        </div>
      </div>
    </div>

    <a-drawer
        title="新增"
        width="600"
        :visible="addShow"
        @close="()=>{this.addShow = false}"
    >
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
       <a-form-model-item label="类型" prop="type">
         <a-radio-group v-model="addForm.type">
           <a-radio value="radio" >
             单选
           </a-radio>
           <a-radio value= "checkbox">
             多选
           </a-radio>
         </a-radio-group>
       </a-form-model-item>
        <a-form-model-item label="分类" >
          <a-select
              placeholder="选择分类"
              v-model="addForm.question_code"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in typeInfo" :key="item.id" :value="item.name">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="题目名称" prop="name">
            <a-input  v-model.trim="addForm.name"  placeholder="输入名称" @change="addTitle" />
          <ul  class="titleList" v-for="item in titleList">
            {{item.name}}
          </ul>
        </a-form-model-item>
        <a-form-model-item label="选项" prop="options">
          <div style="display: flex;align-items: center"  v-for="(item,index) in addForm.options" :key="index">
          <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
            <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
            <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
            <input type="checkbox" :id="'check'+index" @change="resultKey(item,index)">
              正确答案
          </div>
        </a-form-model-item>
        <a-form-model-item label="解析" >
          <a-textarea v-model="addForm.parse" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-drawer
        title="修改"
        width="600"
        :visible="editShow"
        @close="()=>{this.editShow = false}"
    >
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="editForm.type">
            <a-radio value="radio" >
              单选
            </a-radio>
            <a-radio value= "checkbox">
              多选
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="分类" >
          <a-select
              placeholder="选择分类"
              v-model="editForm.question_code"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in typeInfo" :key="item.id" :value="item.name">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="题目名称" prop="name">
          <a-input  v-model="editForm.name"  placeholder="输入名称" @change="editTitle" />
          <ul  class="titleList" v-for="item in titleList">
            {{item.name}}
          </ul>
        </a-form-model-item>
        <a-form-model-item label="选项" prop="options">
          <div style="display: flex;align-items: center"  v-for="(item,index) in editForm.options" :key="index">
            <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
            <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
            <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
            <input type="checkbox"   v-model="item.answer" :id="'check'+index" @change="resultKeyEdit(item,index)">
              正确答案
          </div>
        </a-form-model-item>
        <a-form-model-item label="解析" >
          <a-textarea v-model="editForm.parse" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="editOk">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-modal v-model="typeAdd" title="分类 — 新增" @ok="typeAddOk">
      <a-form-model  :rules="rules"  :model="typeForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addType" >
        <a-form-model-item  label="名称" prop="name">
          <a-input  v-model="typeForm.name"/>
        </a-form-model-item>
        <a-form-model-item label="code" prop="code">
          <a-input  v-model="typeForm.code"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="typeEdit" title="分类 — 修改" @ok="typeEditOk">
      <a-form-model  :rules="rules"  :model="typeEditForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editType" >
        <a-form-model-item  label="名称" prop="name">
          <a-input  v-model="typeEditForm.name"/>
        </a-form-model-item>
        <a-form-model-item label="code" prop="code">
          <a-input  v-model="typeEditForm.code"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-spin>
</div>
</template>

<script>

import {
  delTitleExam,
  getTitleExam,
  getTitleId,
  getTitleList,
  postTitleExam,
  postTitleType, putTitleExam, putTitleType
} from "@/service/titleExam_api";


export default {
  name: "questionBank",
  data(){
    return{
      value: undefined,
      typeShow:false,
      addShow:false,
      editShow:false,
      spinning:false,
      typeAdd:false,
      typeEdit:false,
      typeEditForm:{},
      typeForm:{},
      editForm:{
      },
      addForm:{
        options:[
          {
            answer:0,
            title:'',
          }
        ],
        question_code:[],
        parse:''
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      searchName:'',
      rules: {
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入code值', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'change' }],
        options:[{required:true,message:'请输入选项',trigger: 'blur'}],
        titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
        question_code:[{ required: true, message: '请选择分类', trigger: 'blur' }],
      },
      columns:[
        {
          title: '题目名称',
          dataIndex: 'name',
          width:'50%'
        },
        {
          title: '所属分类',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: '类型',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
      typeInfo:[],
      menu:[0],
      titleList:[],
      titleShow:false
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"题库管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
  mounted() {
    this.getTitleExam()
  },
  methods:{
    async getTitleExam(TitleExam){
      // this.spinning =true
      const exam = await getTitleExam(TitleExam)
      if (exam.code === 0){
        this.typeInfo = exam.data
        this.InfoTable = this.typeInfo[0].tyPe
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
      // this.spinning =false
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.addForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.addForm.options.push({
          answer:0,
          title:''
        })}
    },
    async addTitle(e){
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    //修改题目名称对比
    async editTitle(e) {
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    show() {
      this.typeShow = true;
    },
    hide() {
      this.typeShow = false;
    },
    typeEditBtn(row){
      this.typeEditForm = {
        name : row.name,
        code : row.code
      }
      this.typeEditId = row.id
      this.typeEdit = true
    },
    //修改选项添加
    editOptions(){
      let ifTrue = true
      this.editForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.editForm.options.push({
          answer:0,
          title:'',
        })}
    },
    delEditOptions(item,index){
      this.editForm.options.splice(index, 1)
    },
    delOptions(item,index){
      this.addForm.options.splice(index, 1)
    },

    resultKey(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //修改是否显示
    resultKeyEdit(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //新增题目分类
    async typeAddOk(){
      this.$refs.addType.validate(async valid =>{
        if(valid){
         const add =await postTitleType(this.user_name,this.typeForm)
          if (add.code === 0){
            await this.getTitleExam()
            this.$message.success('添加成功')
            this.$refs.addType.resetFields();
            this.typeAdd = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async typeEditOk(){
      this.$refs.editType.validate(async valid =>{
        if(valid){
          const edit =await putTitleType(this.typeEditId,this.typeEditForm)
          if (edit.code === 0){
            await this.getTitleExam()
            this.$message.success('修改成功')
            this.$refs.editType.resetFields();
            this.typeEdit = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //左侧身份选择
    leftChange(value,index){
      this.InfoTable = value
      this.menuIndex = index
    },
    //搜索
    async searchOk(){
      const exam = await getTitleExam(this.searchName)
      if (exam.code === 0) {
        this.typeInfo = exam.data
        this.InfoTable = this.typeInfo[this.menuIndex].tyPe
      }
    },
    //搜索重置
    searchReset(){
      this.getTitleExam()
      this.menu = [0]
      this.searchName = ''
    },
    async add(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.addForm.options.length;i++){
             arr.push({
              title:this.addForm.options[i].title,
              answer:this.addForm.options[i].answer,
              id:i
            })
          }
          this.addForm.options =arr
          const add =await postTitleExam(this.user_name,this.addForm)
          if (add.code === 0){
            await this.getTitleExam()
            this.InfoTable= this.typeInfo[this.menu].tyPe
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addForm={
              options:[
                {
                  answer:0,
                  title:''
                }
              ],
                  question_code:[],
                  parse:''
            }
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增确认
     addOk(){
      for(let i = 0; i < this.addForm.options.length; i++) {
        if (this.addForm.options[i].answer === 1 ){
          switch (this.addForm.type){
            case 'radio' :
              let num = 0
              for (let  j =0; j<this.addForm.options.length;j++){
                if (this.addForm.options[j].answer === 1){
                  num ++
                }
              }
              if ( num > 1 ){
                this.$message.warning('重新勾选正确答案')
                return false;
              }else {
                this.add()
              }
              break;
            case 'checkbox':
              this.add()
              break;
          }
          return true;
        }
      }
      this.$message.error('未选择正确答案')
      return  false;

    },
    //修改Btn
    async editBtn(id){
      this.editId = id
      const edit =await getTitleId(id)
      if (edit.code === 0){
        this.editForm = edit.data
      }
      this.editShow = true
    },
    async edit(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.editForm.options.length;i++){
            arr.push({
              title:this.editForm.options[i].title,
              answer:this.editForm.options[i].answer,
              id:i
            })
          }
          this.editForm.options =arr
          const add =await putTitleExam(this.editId,this.editForm)
          if (add.code === 0){
            await this.getTitleExam()
            this.InfoTable= this.typeInfo[this.menu].tyPe
            this.$message.success('修改成功')
            this.$refs.editItem.resetFields();
            this.editForm = {}
            this.editShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改
     editOk(){
      for(let i = 0; i < this.editForm.options.length; i++) {
        if (this.editForm.options[i].answer === 1){
          switch (this.editForm.type){
            case 'radio' :
              let num = 0
              for (let  j =0; j<this.editForm.options.length;j++){
                if (this.editForm.options[j].answer === 1){
                  num ++
                }
              }
              if ( num > 1 ){
                this.$message.warning('重新勾选正确答案')
                return false;
              }else {
                this.edit()
              }
              break;
            case 'checkbox':
              this.edit()
              break;
          }
          return true;
        }
      }
      this.$message.error('未选择正确答案')
      return  false;
    },
    async delTitle(id){
      const Info =await delTitleExam(id)
      if (Info.code === 0){
        await this.getTitleExam()
        this.InfoTable= this.typeInfo[this.menu].tyPe
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
  }
}
</script>

<style lang="scss" scoped>
.titleList{
  padding: 0;
  margin: 0;
}
.questionBank{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      height: 70px;
      padding: 20px;
      margin-bottom: 10px;
      background-color: white;
    }
    .table_Info{
      padding: 20px;
      background-color: white;
    }
  }
}
</style>
